import LoadingIndicator from "../LoadingIndicator";

type Props = {
  title: string;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
};

const Button = ({
  title,
  disabled,
  type="submit",
  isLoading = false,
  onClick,
  className,
}: Props) => {

  const isDisabled = disabled || isLoading
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={`w-full text-sm py-3 font-semibold text-white rounded-md mt-0 text-center flex items-center justify-center m-0 ${className} ${isDisabled ? "opacity-30" : ""}`}
    >
      {isLoading === false && <span>{title}</span>}
      {isLoading === true && <LoadingIndicator className="text-gray-400 fill-white" />}
    </button>
  );
};

export default Button;
